.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $gray-600;
  color: $gray-600;
  box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
  50% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px -10px 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px -10px 0 0 $gray-600;
  }
  100% {
    box-shadow: 9984px 0 0 0 $gray-600, 9999px 0 0 0 $gray-600, 10014px 0 0 0 $gray-600;
  }
}