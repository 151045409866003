@import "~styles/variables";

.avatar {
  height: 4rem !important;
  width: 4rem !important;
  background-image: url('~assets/Spin-1s-200px.gif');
  background-size: contain;
  background-color: inherit;

  &.img-loaded {
    background-image: none;
  }

  &.avatar-xs {
    height: 2rem !important;
    width: 2rem !important;
  }

  &.avatar-sm {
    height: 3rem !important;
    width: 3rem !important;
  }

  &.avatar-lg {
    height: 5rem !important;
    width: 5rem !important;
  }

  &.avatar-xl {
    height: 6rem !important;
    width: 6rem !important;

    @media only screen and (max-width: $screen_lg_max) {
      height: 5rem !important;
      width: 5rem !important;
    }
  }

  &.avatar-header {
    height: 10rem !important;
    width: 10rem !important;
    font-size: 5rem;
  }

  &.avatar-header-sm {
    height: 7.5rem !important;
    width: 7.5rem !important;
    font-size: 4rem;
  }

  .alt-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    background-color: inherit;
  }
}

.colored-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.event-avatar {
  height: 96px !important;
  width: 144px !important;

  > img {
    width: auto !important;
    height: auto !important;
    max-width: 100% !important;
    max-height: 100% !important;
  }
}