@import "~styles/variables";

.location-selector {
  display: flex;
  align-items: center;
  height: 100%;

  .location-selector-button {
    height: 100%;
    padding-left: 15px;
    border-radius: 0;
    
    &.pushed {
      background: $gradient_bg;
    }

    .location-selector-button-inner {
    	svg:not(:last-child) {
    		margin-right: 7px;
    	}

      .set-location-text {
        @media (max-width: grid-breakpoint("md")) {
          display: none;
        }
      }

      .location-name {
        color: inherit;
        font-size: $header_item_text_size;
        font-weight: 400;
        text-transform: none;
        white-space: nowrap;
      }

      .device-name {
        color: inherit;
        font-size: $header_item_subtext_size;
        font-weight: 400;
        white-space: nowrap;
      }
    }
  }
  .menu-paper {
    background-color: $header_bg;

    .menu-list {
      padding-top: 0;
      padding-bottom: 0;

      .menu-item {
        color: #fff;
      }
    }
  }
}
