@import "~styles/variables";

.search-bar {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 50px;
  box-shadow: $theme-shadow-1;

  .input-root {
    margin-left: 13px;
    flex: 1;
    line-height: 1.5rem;

    .input {
      padding: 10.5px 0;
    }
  }

  .clear-search {
    transition: color 0.2s ease;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    
    &:hover {
      color: red;
    }
  }

  .search-button {
    border-radius: 50px;
    // height: calc(100% + 2px);
    align-self: stretch;
    margin-right: -1px;
    margin-top: -1px;
    margin-bottom: -1px;

    .search-icon {
      margin-left: 4px;
      margin-right: -4px;
    }
  }
}
