@import "~styles/variables";

.right-drawer-paper {
  z-index: $zindex_checkinbar !important;
  width: $right-drawer-width;
  display: flex;
  flex-direction: column;
  overflow: visible !important;
  transition: all $transition;
  right: -$right-drawer-width !important;
  top: $header_height !important;
  height: calc(100% - #{$header_height}) !important;

  &.open {
    right: 0 !important;
    border-left: none;
    box-shadow: -2px 0px 2px 0px rgba(0,0,0,0.1);
  }
}