@use 'sass:math';
@import "~styles/variables";

$logo_spacing: 0.625rem;
$header-logo-button-max-width: calc((#{$header_height} - #{2*$logo_spacing}) * 1600 / 336 + #{3*$logo_spacing});


.logo-button {
  z-index: inherit;
  padding: $logo_spacing $logo_spacing*1.5 $logo_spacing $logo_spacing*1.5;
  height: $header_height;
  max-width: $header-logo-button-max-width;
  min-width: calc(#{$header-logo-button-max-width} * 0.75);

  .logo-img {
    max-height: $header_height - 2*$logo_spacing;
    max-width: 100%;
    height: auto;
  }
}
