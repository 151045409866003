.giftcard-type-search {
  .giftcard-type-search-item {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .react-select-container {
    .react-select__control {
      .react-select__value-container {
        .react-select__single-value {
          width: calc(100% - 12px);
        }
      }
    }
  }
}
