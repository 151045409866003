@import "~styles/variables";

.data-list {
  background-color: $list_bg !important;

  .header {
    .search-row {
      display: flex;
      padding: $spacer*.75 $spacer;
      align-items: center;

      .search-bar {
        flex: 1;
      }

      .action-wrap {
        margin-left: $spacer*.75;
      }
    }

    .filter-row {
      display: flex;
      justify-content: space-between;
    }

    .filters-wrap {
      display: flex;
      align-items: center;
    }

    .filter-control {
      margin-left: $spacer;
    }
    
    .order-chip {
      margin: 0 $spacer $spacer*.75 $spacer;      
    }
  }

  .list-wrapper {
    .list {
      background-color: $list_item_bg;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .sort-by-wrap {
    min-width: 150px;
  }
  
  .order-wrap {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .pagination {
    display: flex;
    padding: $spacer*.5 $spacer*.5 $spacer*.5 $spacer;

    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .page-buttons {
      margin-left: auto;

      .page-button {
        min-width: 2rem;

        &.current {
          border: 1px solid #979797;
        }
      }
    }
  }
}
