@import "~styles/variables";

.scannable-field {
  display: flex;
  align-items: flex-start;

  &:focus-within fieldset {
    border-color: $success-hover !important;
  }

  &:focus-within::after {
    content: "Ready for scan";
    color: $success-hover;
    font-style: italic;
    font-size: 0.875rem;
    margin-left: $spacer*.5;
    margin-top: 1.25rem;
    white-space: nowrap;
  }

  .MuiFormHelperText-root {
    text-wrap: wrap;
  }
}