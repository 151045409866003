@import "~styles/variables";

.price-row {
  .price-row-label {
    white-space: nowrap;
  }

  & > td {
    border-bottom: none;
    padding: $spacer*.5 !important;
  }

  & > td:last-child {
    padding-left: 16px;
  }

  &.total-row,
  &.promo-code-row {
    & > td {
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  }

  &.total-row {
    & > td {
      padding: $spacer $spacer*.5 !important;
    }

    .price-row-label, .price-row-value {
      font-weight: bold;
    }
  }

  &.discount-row,
  &.promo-code-row {
    .price-row-label, .price-row-value {
      color: $danger;
    }
  }
}