@use 'sass:math';
@import "~styles/variables";

#header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all $transition !important;
  padding: 0;
  border: 0;
  text-align: center;
  position: relative;
  z-index: $zindex_header !important;
  background-color: $header_bg;
  height: $header_height;

  .actions-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: inherit;

    &.actions-left {
      padding-left: 8px;
    }

    &.actions-right {
      padding-right: 8px;
      color: #fff;

      > .disabled {
        pointer-events: none;
        color: $btn-link-disabled-color;
      }
    }
  }

  .app-header-center {
    padding-left: 8px;
    flex: 1;
    z-index: inherit;
    display: flex;
  }

  .header-icon {
    display: inline-block;
    height: $header_height;
    padding: math.div($header-height - $header-line-height, 2) 14px;

    @media only screen and (min-width: $screen_md_min) {
      padding-left: 18px;
      padding-right: 18px;
    }

    .material-icons {
      font-size: 24px;
    }
  }

  .right-drawer-open & {
    width: 100%;
  }

  .toggle-sidebar-btn {
    cursor: pointer;
  }

  @media only screen and (min-width: $screen_md_min) {
    text-align: inherit;

    .toggle-sidebar-btn {
      display: none;
    }
  }

  .app-main & {
    > .app-header-inner {
      max-width: 100%;
      margin: auto;
      transition: max-width .35s ease;
    }
  }

  &.bg-transparent {
    background-color: transparent !important;
  }
}

.header-dropdown-menu {
  top: 50px !important;

  .header-dropdown-item {
    font-size: 14px !important;

    .header-dropdown-text {
      margin-left: 5px;
      margin-right: 5px;
    }

    .text-muted {
      font-size: $font-size-sm !important;
    }
  }
}