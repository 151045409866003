@import "~styles/variables";

.nested-vert-router {
  display: flex;
  margin-top: $spacer;

  .vert-menu {
    // max-width: 15vw;
    min-width: 180px;
    padding: 0 $spacer 0 0;
    background-color: transparent;

    @media only screen and (max-width: $screen_md_max) {
      min-width: 150px;
    }

    .vert-menu-hamburger {
      margin-bottom: 6px;
    }

    .nested-vert-menu-item {
      padding: 8px 32px 8px 12px;
      border-radius: 4px;
      height: 48px;

      > .MuiListItemIcon-root {
        min-width: 40px;
      }

      > .MuiListItemText-inset {
        padding-left: 40px;
      }

      + .MuiListItemSecondaryAction-root > .MuiSvgIcon-root {
        vertical-align: middle;
      }

      .item-text {
        .primary {
          color: #606060;
          font-weight: 400;
        }
        
        @media only screen and (max-width: $screen_md_max) {
          padding-left: 40px;
          padding-right: 0;

          &.with-icon {
            padding-left: 0;
          }
        }
      }

      &.active-menu-item { 
        background-color: rgba(59, 75, 98, 0.2);
      }

      &.nested-vert-subheader {
        margin-top: $spacer;

        .item-text {
          .primary {
            font-weight: 500;
          }
        }
      }

      &.nested-vert-menu-subitem {
        > .item-text {
          > .primary {
            padding-left: 16px;
          }
        }
      }
    }

    &.collapsed {
      min-width: initial;

      .nested-vert-menu-item {
        padding: 8px 8px 8px 12px;

        > .MuiListItemIcon-root {
          min-width: 0;
        }

        .item-text {
          display: none;
        }
      }
    }
  }

  .router-content {
    flex: 1;
    background-color: transparent;
    border: none;
    position: relative;
  }
}
