@import "variables";

#root {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

#background {
  width: 100%;
  height: 100%;
  background-color: $body-bg;
  display: flex;
  flex-direction: column;
}