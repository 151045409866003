@import "~styles/variables";

.user-icon {
  border-left: 1px solid lighten($header_bg, 10%);
  border-right: 1px solid lighten($header_bg, 10%);
  
	.user-icon-button {
		padding: 9px 15px;
    border-radius: 0;

    &.pushed {
      background: $gradient_bg;
    }

    .user-icon-avatar {
      width: 46px;
      height: 46px;
    }

    .user-icon-button-label {
      color: inherit;
      font-size: 0.9rem;
      font-weight: 500;
      margin-left: 15px;
      white-space: nowrap;

      @media (max-width: grid-breakpoint("md")) {
        display: none;
      }
    }
	}

  .user-icon-popper {
    left: 5px !important;

    .menu-paper {
      background-color: $header_bg;

      .menu-list {
        padding-top: 0;
        padding-bottom: 0;

        .menu-item {
          padding-top: 8px;
          padding-bottom: 8px;

          .menu-item-icon {
            color: #fff;
            margin-right: 0;
          }

          .menu-item-text {
            color: #fff;
          }

          .menu-item-primary {
            color: $header_item_text_color;
            font-size: $header_item_text_size;
          }

          .menu-item-secondary {
            color: $header_item_subtext_color;
            font-size: $header_item_subtext_size;
          }

          &.lock-item {
            background-color: $approach-logo-orange;
          }

          &.reset-item {
            .menu-item-icon {
              color: $danger;
            }
          }
        }
      }
    }
  }
}