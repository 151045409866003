.toast-container {
  bottom: 0 !important;
  padding-bottom: 0 !important;

  .toast {
    background-color: $gray-900;
    color: $gray-200;
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 48px;

    &.Toastify__toast--error {
      background-color: $danger;
    }

    &.Toastify__toast--success {
      background-color: $success;
    }
    
    .toast-body {
      font-size: 0.875rem;
    }

    .Toastify__close-button {
      color: #fff !important;
      opacity: 0.7 !important;
    }

    .toast-progress {
      background: rgba(255, 255, 255, 0.7) !important;
    }
  }
}