@import "~styles/variables";

.main-app-container {
  height: 100vh;

  .app-page-container {
    width: 100%;
    height: 100%;

    @media only screen and (max-width: $screen_sm_max) {
      position: relative;
      z-index: $zindex_page_container;
      padding-left: 0;
      transition: transform .25s ease;
      background-color: $white; 

      .sidebar-open & {
        overflow: hidden;
        position: fixed;
        transform: translateX($left-drawer-width);
      }
    }
  }
}
