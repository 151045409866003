.text-xsmall { font-size: $font-size-xs !important; }
.text-small { font-size: $font-size-sm !important; }
.text-normal { font-size: $font-size-base !important; }
.text-large { font-size: $font-size-lg !important; }
.text-xlarge { font-size: $font-size-xl !important; }

.color-primary,
a.color-primary { 
  color: theme-color("primary");
  &:hover { color: theme-color("primary"); }
}
.color-success,
a.color-success { 
  color: theme-color("success") !important;
  &:hover { color: theme-color("success") !important; }
}
.color-info,
a.color-info { 
  color: theme-color("info");
  &:hover { color: theme-color("info"); }
}
.color-warning,
a.color-warning { 
  color: theme-color("warning");
  &:hover { color: theme-color("warning"); }
}
.color-danger,
a.color-danger { 
  color: theme-color("danger");
  &:hover { color: theme-color("danger"); }
}
.color-dark,
a.color-dark { 
  color: $dark;
  &:hover { color: $dark; }
}
.color-white,
a.color-white { 
  color: $white;
  &:hover { color: $white; }
}
.color-gray-darker,
a.color-gray-darker { 
  color: $gray-200;
  &:hover { color: $gray-200; }
}
.color-gray-dark,
a.color-gray-dark { 
  color: $gray-800;
  &:hover { color: $gray-800; }
}
.color-gray,
a.color-gray { 
  color: $gray-700;
  &:hover { color: $gray-700; }
}
.color-gray-light,
a.color-gray-light { 
  color: $gray-light;
  &:hover { color: $gray-light; }
}
.color-gray-lighter,
a.color-gray-lighter { 
  color: $gray-200;
  &:hover { color: $gray-200; }
}
