@import "~styles/variables";

.copy-to-clipboard-dialog {
  .dialog-content {
    .html-field {
      > div {
        font-family: monospace;
      }
    }
  }

  .dialog-actions {
    .copy-to-clipboard-button {
      transition: background-color 0.7s, color 0.7s;

      &.copied {
        background-color: $success;
        color: #fff;
      }
    }
  }
}