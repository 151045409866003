.order-list {
  .order-expansion-panel {
    .card-content {
      padding: 8px 16px;
    }

    .card-actions {
      justify-content: flex-end;
      padding: 16px;
    }
  }
}