@import "~styles/variables";

.calendar-search {
  .calendar-search-item {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &.dense {
    .react-select-container {
      .react-select__control {
        background-color: #fff;
        
        .react-select__value-container {
          .react-select__input {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
}
