@import "~styles/variables";

#center-section {
  padding: $center-section-spacing;
  height: 100%;
  width: $center-section-width;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .right-drawer-open & {
    margin-right: $right-drawer-width;
    width: calc(#{$center-section-width} - #{$right-drawer-width});
  }

  .left-drawer-open & {
    margin-left: $left-drawer-width;
    width: calc(#{$center-section-width} - #{$left-drawer-width});
  }

  .right-drawer-open.left-drawer-open & {
    width: calc(#{$center-section-width} - #{$right-drawer-width} - #{$left-drawer-width});
  }
}