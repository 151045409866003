@import "~styles/variables";

.customer-search {
  min-width: 16rem !important;

  .customer-search-item {
    margin-top: 4px;
    margin-bottom: 4px;

    .customer-name {
       margin-left: 8px;
    }
  }
}