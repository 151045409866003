@import "~styles/variables";

#content {
  position: relative;
  width: 100%;
  height: calc(100% - #{$header_height});
  flex: 1;
  z-index: 1;

  @supports(padding: max(0px)) {
    padding-top: m#{a}x(0px, env(safe-area-inset-top));
    padding-bottom: m#{a}x(0px, env(safe-area-inset-bottom));
    padding-left: m#{a}x(0px, env(safe-area-inset-left));
    padding-right: m#{a}x(0px, env(safe-area-inset-right));
  }
}