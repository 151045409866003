@import "variables";

.picky {
  &[aria-expanded="true"] {
    // height: 339px;
  }
  
  .picky__input {
    padding: 10.5px 14px;
    height: calc(1.1876em + 21px);
    line-height: 1.1876em;
    vertical-align: middle;
    border: 1px solid $border-color;
    border-radius: 4px;

    &:hover {
      border-color: rgba(0, 0, 0, 0.87);
    }
  }

  .picky__dropdown {
    position: sticky !important;

    .option {
      padding: 0;
    }
  }
}
