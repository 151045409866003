.ticket-status-filter {
  .picky__dropdown {
    position: absolute !important;
    z-index: 1;
    margin-top: 1px;
  }

  .picky .picky__input {
    min-width: 10rem;
  }
}