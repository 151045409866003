@import "~styles/variables";

.toggle-left-drawer {
  color: #fff !important;
}

@media (min-width: $screen_xl_min) {
  .toggle-left-drawer {
    display: none !important;
  }
}
