.MuiOutlinedInput-inputSelect {
  padding-right: 32px !important;
}
// .MuiListItemSecondaryAction-root {
//   right: 4px !important;
// }
.MuiListItem-secondaryAction {
  padding-right: 64px;
}
label.MuiFormControlLabel-root {
  margin: 0;
}
.form-control-label-inline {
  display: inline-flex;
  align-items: center;
}
.inline-field {
  margin-top: 8px !important;
  margin-bottom: 8px !important;

  .MuiFormHelperText-root {
    position: absolute;
    top: 40px;
  }
}
.MuiNativeSelect-select:focus {
  background-color: initial !important;
}
