table.table-dense {
  > thead {
    > tr {
      > th {
        padding: $table-cell-padding-sm;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        font-size: 0.8625rem;
        padding: $table-cell-padding-sm;
      }
    }
  }
}

table.auto-layout-table {
  width: auto;
  table-layout: auto;
}

table.thick-border-first-row {
  > thead {
    > tr {
      > th {
        border-bottom: 2px solid #666;
      }
    }
  }
}

table.thick-border-first-col {
  > thead {
    > tr {
      > th:first-child {
        border-right: 2px solid #666;
      }
    }
  }

  > tbody {
    > tr {
      > td:first-child {
        border-right: 2px solid #666;
      }
    }
  }
}

table.striped {
  > thead > tr,
  > tbody > tr:nth-of-type(even) {
    background-color: $body-bg;
  }
}

.ds-data-table {
  position: relative;
  background-color: $list_bg !important;
  display: flex;
  flex-direction: column;
  width: 100%;

  .ds-data-table-header {
    // border-bottom: 1px solid $list_item_divider_color;
    border-bottom: 1px solid $dark;
    background-color: inherit;
    
    .header-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: $spacer*.75 $spacer;
      // justify-content: space-between;
      align-items: center;
      background-color: inherit;

      .header-row-section {
        background-color: inherit;

        .header-row-item {
          background-color: inherit;

          &.new-button {
            white-space: nowrap;
          }
        }

        .header-row-item + .header-row-item {
          margin-left: $spacer*.75;
        }
      }

      .header-row-section + .header-row-section {
        margin-left: $spacer*.75;
      }

      .left-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .center-section {
        flex: 1;
      }

      .right-section {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .center-section + .right-section {
        flex: 0;
      }
    }

    .mass-action-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: inherit;
      height: 48px;

      .center-section {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .header-row + .mass-action-row {
      margin-top: $spacer * -0.75;
    }

    .filter-row {
      display: flex;
      justify-content: space-between;
      padding: $spacer*.5 $spacer;
    
      .order-chips {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .order-chip {
          margin: 0 $spacer $spacer*.5 $spacer;      
        }
      }
    }
  }

  .ds-data-table-content {
    background-color: inherit;
    flex: 1;

    .no-items-message {
      &.overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 1;
        background-color: $list_bg;
        z-index: 1;
      }
    }
  }
}