@import "~styles/variables";

.generic-search {
  background-color: inherit;

  .input-label {
    padding-left: 8px;
    padding-right: 8px;
    left: -4px;
    background-color: inherit;

    &.disabled {
      color: $text-disabled;
    }
  }

  .react-select-container {
    .react-select__control {
      cursor: text;
      background-color: transparent;

      &.react-select__control--is-disabled {
        border-color: $theme-action-disabled;
      }

      &:hover {
        border-color: $dark;
      }

      .react-select__value-container {
        padding-left: 11px;

        .react-select__input {
          padding-top: 0;
          padding-bottom: 0;
        }

        .react-select__placeholder {
          color: #A2A2A2;
        }
      }

      .react-select__indicators {
        .react-select__clear-indicator {
          cursor: pointer;
        }
      }
    }
    
    &.error .react-select__control {
      border-color: #f44336;
    }

    &.error .react-select__control--is-focused {
      box-shadow: 0 0 0 1px #f44336;
    }

    .react-select__menu {
      z-index: 2;
    }
  }
}