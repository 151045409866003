@import "variables";

.smart-form {
  position: relative;

  .field-wrapper {
    background-color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .MuiFormLabel-root {
      color: $gray-700;
      font-weight: $font-weight-thick;
    }

    .MuiFormGroup-root {
      background-color: inherit;
    }
    
    .checkbox-field {
      margin: 0;
    }

    .radio-group-item {
      align-self: flex-start;
    }

    .radio-group-item + .radio-group-item {
      margin-top: $spacer;
    }
  }
  
  .field-wrapper + .field-wrapper {
    margin-top: $spacer;
  }

  .smart-form-content {
    background-color: inherit;
  }

  .smart-form-actions {
    justify-content: flex-end;
    padding: 0 $spacer $spacer;
  }

  .field-group-divider {
    margin-top: $spacer;
    margin-bottom: $spacer;
  }

  .field-group-header {
    margin-top: $spacer*.5;
    margin-bottom: $spacer*.5;
  }
}
