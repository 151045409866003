@import "~styles/variables";

.product-search-dialog {
  position: relative;
  
  .dialog-appbar {
    box-shadow: none;
    
    .dialog-toolbar {
      .dialog-title {
        flex: 1;
      }
    }
  }

  .dialog-content {
    background-color: $body-bg;
    height: 400px;
    width: $screen_sm_min;

    .generic-search .react-select-container .react-select__control {
      background-color: #fff;
    }
  }

  .dialog-actions {
    background-color: $body-bg;
  }
}
