// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
    &.Mui-disabled {
      background-color: $theme-action-disabled-background !important;
    }
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
  button#{$parent} {
    @include hover {
      background-color: darken($color, 10%) !important;
    }
  }
}
