.calendar-color-picker {
  .calendar-color-picker-colors {
    padding: 13px;

    .calendar-color-picker-row {
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      text-rendering: geometricPrecision;

      .calendar-color-picker-item {
        border-radius: 50%;
        outline: none;
        width: 18px;
        height: 18px;
        margin: 3px;
        cursor: pointer;
        position: relative;

        .calendar-color-picker-selected {
          text-align: center;
          color: #fff;
          font-size: 18px;
          transition: opacity 100ms linear;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}