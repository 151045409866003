@import "~styles/variables";

.staff-search {
  .staff-search-item {
    margin-top: 4px;
    margin-bottom: 4px;

    .staff-name {
       margin-left: 8px;
    }
  }
}
