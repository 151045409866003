@import "~styles/variables";

.cart-totals-wrapper {
  min-height: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .cart-totals {
    .totals-row {
      height: initial;
    }
    
    .total-cell {
      text-align: right;
      padding: 2px 10px;
      border-bottom: none;

      .remove-cash-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .remove-cash-button {
          margin-right: $spacer;
          font-size: 0.65rem;
          padding: 0;
        }
      }
    }

    .divider-cell {
      padding: 2px 4px;
      border-bottom: none;
      position: relative;

      .cash-divider {
        margin-left: 28px;
      }

      svg {
        position: absolute;
        top: -9px;
        background-color: transparent;
        fill: $gray-500;
      }
    }
  }
}