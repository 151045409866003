@import "~styles/variables";

.data-table {
  background-color: inherit;

  .data-table-head {
    .data-table-cell {
      border-bottom-color: $dark;
      white-space: nowrap;
    }
  }

  .data-table-body {
    background-color: inherit;

    .data-table-row {
      background-color: inherit;

      &.row-pointer {
        cursor: pointer;
      }

      &.is-dragging {
        background-color: $scandal;
      }

      .data-table-cell {
        background-color: inherit;
      }

      &.expansion-panel-row {
        .expansion-panel-cell {
          .expansion-panel-details {
            padding: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            display: flex;
            flex-direction: column;

            .expansion-panel-description {
              padding: $spacer;
            }
          }
        }
      }
    }
  }
}

td.min-width {
  width: 1% !important;
}
