@import "~styles/variables";

.remove-fab {
  position: absolute !important;
  top: 3px;
  right: 3px;
  background-color: $danger-hover !important;
  opacity: 0;
  transition: all 0.3s ease !important;
  width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;

  svg {
    width: 10px !important;
    height: 10px !important;
    color: #fff !important;
  }
}