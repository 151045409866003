@import "~styles/variables";

.richtext-field {
  background-color: inherit;

  .input-label {
    padding-left: 8px;
    padding-right: 8px;
    left: -4px;
    background-color: inherit;

    &.disabled {
      color: $text-disabled;
    }
  }

  .richtext-wrapper {
    padding: 8px;
    background-color: transparent;
    border: 1px solid $border-color;
    border-radius: 4px;

    .rdw-editor-toolbar{
      .editor-toolbar-custom-button {
        background-color: transparent;
        border: 1px solid $primary-dark;
        color: $primary-dark;
        border-radius: 4px;
        margin: 4px;
      }
    }

    .richtext-editor {
      min-height: 275px !important;
      border: 1px solid #F1F1F1 !important;
      padding: 5px !important;
      border-radius: 2px !important;
    }
  }
}
