@import "variables";

.transaction-list {
  height: 100%;

  .model-dynamic-list-view {
    height: calc(100% - #{$breadcrumb-min-height} - #{$center-section-spacing});

    .ds-data-table {
      height: 100%;

      .ds-data-table-content {
        overflow-x: auto;
      }
    }
  }
}
