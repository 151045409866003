@use 'sass:math';
@import "functions";

// Variables

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code


//
// Color system
//

$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #e0e0e0 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #757575 !default;
$gray-700: #495057 !default;
$gray-750: #404040 !default;
$gray-800: #343a40 !default;
$gray-900: #212121 !default;
$black:  #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900
) !default;

$blue:    #42A5F5 !default;
$indigo:  #6610f2 !default;
$purple:  #7E57C2 !default;
$pink:    #e83e8c !default;
$red:     #e91e63 !default;
$orange:  #FFCA28 !default;
$yellow:  #ffc107 !default;
$green:   #66BB6A !default;
$teal:    #20c997 !default;
$cyan:    #00BCD4 !default;
$lime:    #cddc39 !default;
$big-stone: #2F4550 !default;
$blue-bayoux: #586F7C !default;
$scandal: #B8DBD9 !default;
$ghost-white: #F4F4F9 !default;
$nero: rgba(0, 0, 0, 0.87) !default;
$dark-grey: #212121;
$bright: #fafafa !default;
$gray-light: #636c72 !default;
$gray-light2: #999 !default;
$approach-logo-orange: #EE5825 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  lime: $lime,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
) !default;

$primary: #03a9f4 !default;
$primary-light: #cde3f3;
$primary-dark: #006db3;
$secondary: #e10050 !default;
$secondary-light: rgba($secondary, 0.2);
$secondary-dark: #9d0038;

$tertiary: $scandal !default;
$light: $ghost-white !default;
$dark: $nero !default;
$success: #23ce6b !default;
$success-hover: #369539 !default;
$danger: $red !default;
$danger-hover: #D50000 !default;
$info: $cyan !default;
$warning: $yellow !default;

$gradient_bg: linear-gradient(to right bottom, #115b7d, #206485, #2c6c8d, #377595, #417e9d) !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $gray-100,
  "gray": $gray-600,
  "dark": $gray-800,
  "black": $black
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

$theme-action-active: rgba(0, 0, 0, 0.54);
$theme-action-hover: rgba(0, 0, 0, 0.08);
$theme-action-selected: rgba(0, 0, 0, 0.14);
$theme-action-disabled: rgba(0, 0, 0, 0.26);
$theme-action-disabled-background: rgba(0, 0, 0, 0.12);

// Theme Shadows
$theme-shadow-0: none !default;
$theme-shadow-1: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-2: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-3: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12) !default;
$theme-shadow-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12) !default;

$theme-shadows: (
  0: $theme-shadow-0,
  1: $theme-shadow-1,
  2: $theme-shadow-2,
  3: $theme-shadow-3,
  4: $theme-shadow-4,
  5: $theme-shadow-5,
  6: $theme-shadow-6,
  7: $theme-shadow-7,
  8: $theme-shadow-8,
  9: $theme-shadow-9,
  10: $theme-shadow-10,
  11: $theme-shadow-11,
  12: $theme-shadow-12,
  13: $theme-shadow-13,
  14: $theme-shadow-14,
  15: $theme-shadow-15,
  16: $theme-shadow-16,
  17: $theme-shadow-17,
  18: $theme-shadow-18,
  19: $theme-shadow-19,
  20: $theme-shadow-20,
  21: $theme-shadow-21,
  22: $theme-shadow-22,
  23: $theme-shadow-23,
  24: $theme-shadow-24
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  false !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;


// Body
$body-bg: #f2f2f2;
$body-color: $dark !default;


// Header
$header_bg: #0b3956 !default;
$header_box_shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
$header_item_text_color: #fff !default;
$header_item_text_size: 0.9rem;
$header_item_subtext_color: #919aa7 !default;
$header_item_subtext_size: 0.8rem;

$page-header-bg: #f8f8f8;
$page-header-active-tab-underline: #fff; 
$page-header-color: $gray-750;


// Links
$link-color:            theme-color("primary") !default;
$link-decoration:       none !default;
$link-hover-color:      darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
$hover-opacity:         .04 !default;


// Paragraphs
$paragraph-margin-bottom: 1rem;


// Lists
$list_bg: #f8f8f8 !default;
$list_item_bg: #fff !default;
$list_item_text_color: #717171 !default;
$list_item_text_size: 0.875rem;
$list_item_subtext_color: #000 !default;
$list_item_subtext_size: 1rem;
$list_item_divider_color: rgba(0, 0, 0, 0.12) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
  //xl: 1280px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

$screen_xl_min: grid-breakpoint("xl");
$screen_lg_max: ($screen_xl_min - 1);
$screen_lg_min: grid-breakpoint("lg");
$screen_md_max: ($screen_lg_min - 1);
$screen_md_min: grid-breakpoint("md");
$screen_sm_max: ($screen_md_min - 1);
$screen_sm_min: grid-breakpoint("sm");
$screen_xs_max: ($screen_sm_min - 1);
$screen_xs_min: grid-breakpoint("xs");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:         1.5 !default;
$line-height-sm:         1.5 !default;

$border-width: 1px !default;
$border-color: rgba(0, 0, 0, 0.23) !default;

$border-radius:          .2rem !default;
$border-radius-lg:       .2rem !default;
$border-radius-sm:       .2rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow-md:               0 .25rem .5rem rgba($black, .1) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

$component-active-color: $white !default;
$component-active-bg:    theme-color("primary") !default;

$caret-width:            .3em !default;

$transition-base:        all .2s ease-in-out !default;
$transition-fade:        opacity .15s linear !default;
$transition-collapse:    height .35s ease !default;


// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Roboto", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.25rem !default;
$font-size-xl:   1.5rem !default;
$font-size-sm:   .875rem !default;
$font-size-xs:   .75rem !default;

$font-weight-light: 300 !default;
$font-weight-normal: normal !default;
$font-weight-thick:  500 !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$headings-margin-bottom: math.div($spacer, 2) !default;
$headings-font-family:   inherit !default;
$headings-font-weight:   500 !default;
$headings-line-height:   1.1 !default;
$headings-color:         inherit !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight:     300 !default;
$display2-weight:     300 !default;
$display3-weight:     300 !default;
$display4-weight:     300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size:   1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;
$text-primary: $nero !default;
$text-secondary: $theme-action-active !default;
$text-disabled: rgba(0, 0, 0, 0.38) !default;

$blockquote-small-color:  $gray-600 !default;
$blockquote-font-size:    ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black,.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem !default;
$table-cell-padding-sm:         .5rem !default;

$table-bg:                      transparent !default;
$table-accent-bg:               rgba($black,.05) !default;
$table-hover-bg:                rgba($black,.075) !default;
$table-active-bg:               $table-hover-bg !default;

$table-border-width:            $border-width !default;
$table-border-color:            rgba($black,.1) !default;

$table-head-bg:                 $gray-200 !default; // $gray-lighter
$table-head-color:              $gray-700 !default; // $gray

$table-inverse-bg:              $gray-900 !default; // $gray-dark
$table-inverse-accent-bg:       rgba($white, .05) !default; // $body-bg
$table-inverse-hover-bg:        rgba($white, .075) !default; // rgba($black,.08)
$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
$table-inverse-color:           $body-bg !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y:       .5rem !default;
$input-btn-padding-x:       .75rem !default;
$input-btn-line-height:     1.25 !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .5rem !default;
$input-btn-line-height-sm:  1.5 !default;

$input-btn-padding-y-lg:    .75rem !default;
$input-btn-padding-x-lg:    1.5rem !default;
$input-btn-line-height-lg:  1.5 !default;

$btn-font-weight:                $font-weight-normal !default;
$btn-box-shadow:                 inset 0 1px 0 rgba($white,.15), 0 1px 1px rgba($black,.075) !default;
$btn-focus-box-shadow:           0 0 0 3px rgba(theme-color("primary"), .25) !default;
$btn-active-box-shadow:          inset 0 3px 5px rgba($black,.125) !default;

$btn-link-disabled-color:        $gray-600 !default;

$btn-block-spacing-y:            .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius !default;
$btn-border-radius-lg:           $border-radius-lg !default;
$btn-border-radius-sm:           $border-radius-sm !default;

$btn-transition:                 background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;


// Forms

$input-bg:                       $white !default;
$input-disabled-bg:              $gray-200 !default;

$input-color:                    $gray-700 !default;
$input-border-color:             rgba($black,.15) !default;
$input-btn-border-width:         $border-width !default; // For form controls and buttons
$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

$input-border-radius:            $border-radius !default;
$input-border-radius-lg:         $border-radius-lg !default;
$input-border-radius-sm:         $border-radius-sm !default;

$input-focus-bg:                 $input-bg !default;
$input-focus-border-color:       lighten(theme-color("primary"), 25%) !default;
$input-focus-box-shadow:         $input-box-shadow, $btn-focus-box-shadow !default;
$input-focus-color:              $input-color !default;

$input-placeholder-color:        $gray-600 !default;

$input-height-border:           $input-btn-border-width * 2 !default;

$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height:                  calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:         ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top:     .25rem !default;

$form-check-margin-bottom:  .5rem !default;
$form-check-input-gutter:   1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom:       1rem !default;

$input-group-addon-color:        $input-color !default;
$input-group-addon-bg:           $gray-200 !default;
$input-group-addon-border-color: $input-border-color !default;
$input-group-btn-border-color:   $input-border-color !default;

$custom-control-gutter:   1.5rem !default;
$custom-control-spacer-y: .25rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg:         #ddd !default;
$custom-control-indicator-bg-size:    50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1) !default;

$custom-control-indicator-disabled-bg:       $gray-200 !default;
$custom-control-description-disabled-color:  $gray-600 !default;

$custom-control-indicator-checked-color:      $white !default;
$custom-control-indicator-checked-bg:         theme-color("primary") !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color("primary") !default;

$custom-control-indicator-active-color:      $white !default;
$custom-control-indicator-active-bg:         lighten(theme-color("primary"), 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg: theme-color("primary") !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y:          .375rem !default;
$custom-select-padding-x:          .75rem  !default;
$custom-select-height:              $input-height  !default;
$custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height:         $input-btn-line-height !default;
$custom-select-color:               $input-color !default;
$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg:            $white !default;
$custom-select-disabled-bg:   $gray-200 !default;
$custom-select-bg-size:       8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator:     str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width:  $input-btn-border-width !default;
$custom-select-border-color:  $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: lighten(theme-color("primary"), 25%) !default;
$custom-select-focus-box-shadow:   inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm:  75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-file-height:           $input-height !default;
$custom-file-width:            14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary") !default;

$custom-file-padding-y:     $input-btn-padding-y !default;
$custom-file-padding-x:     $input-btn-padding-x !default;
$custom-file-line-height:   $input-btn-line-height !default;
$custom-file-color:         $input-color !default;
$custom-file-bg:            $input-bg !default;
$custom-file-border-width:  $input-btn-border-width !default;
$custom-file-border-color:  $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow:    $input-box-shadow !default;
$custom-file-button-color:  $custom-file-color !default;
$custom-file-button-bg:     $input-group-addon-bg !default;
$custom-file-text: (
  placeholder: (
    en: "Choose file..."
  ),
  button-label: (
    en: "Browse"
  )
) !default;


// Form validation
$form-feedback-valid-color:   theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:             10rem !default;
$dropdown-padding-y:             .5rem !default;
$dropdown-spacer:                .125rem !default;
$dropdown-bg:                    $white !default;
$dropdown-border-color:          rgba($black,.15) !default;
$dropdown-border-width:          $border-width !default;
$dropdown-divider-bg:            $gray-200 !default; // gray-lighter
$dropdown-box-shadow:            0 .5rem 1rem rgba($black,.175) !default;

$dropdown-link-color:            $gray-900 !default; // $gray-dark
$dropdown-link-hover-color:      darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:         $gray-100 !default; // $gray-lightest

$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg:        $component-active-bg !default;

$dropdown-link-disabled-color:   $gray-600 !default;

$dropdown-item-padding-y:        .25rem !default;
$dropdown-item-padding-x:        1.5rem !default;

$dropdown-header-color:          $gray-600 !default; // $gray-light


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:           1000 !default;
$zindex-sticky:             1020 !default;
$zindex-fixed:              1030 !default;
$zindex-modal-backdrop:     1040 !default;
$zindex-modal:              1050 !default;
$zindex-popover:            1060 !default;
$zindex-tooltip:            1070 !default;

// Navs

$nav-link-padding-y:            .5rem !default;
$nav-link-padding-x:            1rem !default;
$nav-link-disabled-color:       $gray-600 !default;

$nav-tabs-border-color:                       #ddd !default;
$nav-tabs-border-width:                       $border-width !default;
$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color:            $gray-200 !default;
$nav-tabs-link-active-color:                  $gray-700 !default;
$nav-tabs-link-active-bg:                     $body-bg !default;
$nav-tabs-link-active-border-color:           #ddd !default;

$nav-pills-border-radius:     $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg:    $component-active-bg !default;

// Navbar

$navbar-padding-y:                  math.div($spacer, 2) !default;
$navbar-padding-x:                  $spacer !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-height:               ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y:            math.div($navbar-brand-height - $nav-link-height, 2) !default;

$navbar-toggler-padding-y:           .25rem !default;
$navbar-toggler-padding-x:           .75rem !default;
$navbar-toggler-font-size:           $font-size-lg !default;
$navbar-toggler-border-radius:       $btn-border-radius !default;

$navbar-dark-color:                 rgba($white,.5) !default;
$navbar-dark-hover-color:           rgba($white,.75) !default;
$navbar-dark-active-color:          rgba($white,1) !default;
$navbar-dark-disabled-color:        rgba($white,.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color:  rgba($white,.1) !default;

$navbar-light-color:                rgba($black,.5) !default;
$navbar-light-hover-color:          rgba($black,.7) !default;
$navbar-light-active-color:         rgba($black,.9) !default;
$navbar-light-disabled-color:       rgba($black,.3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black,.1) !default;

// Pagination

$pagination-padding-y:                .5rem !default;
$pagination-padding-x:                .75rem !default;
$pagination-padding-y-sm:             .25rem !default;
$pagination-padding-x-sm:             .5rem !default;
$pagination-padding-y-lg:             .75rem !default;
$pagination-padding-x-lg:             1.5rem !default;
$pagination-line-height:              1.25 !default;

$pagination-color:                     $link-color !default;
$pagination-bg:                        $white !default;
$pagination-border-width:              $border-width !default;
$pagination-border-color:              #ddd !default;

$pagination-hover-color:               $link-hover-color !default;
$pagination-hover-bg:                  $gray-200 !default;
$pagination-hover-border-color:        #ddd !default;

$pagination-active-color:              $white !default;
$pagination-active-bg:                 theme-color("primary") !default;
$pagination-active-border-color:       theme-color("primary") !default;

$pagination-disabled-color:            $gray-600 !default;
$pagination-disabled-bg:               $white !default;
$pagination-disabled-border-color:     #ddd !default;


// Jumbotron

$jumbotron-padding:              2rem !default;
$jumbotron-bg:                   $gray-200 !default;


// Cards

$card-spacer-y:            .75rem !default;
$card-spacer-x:            1.25rem !default;
$card-border-width:        1px !default;
$card-border-radius:       $border-radius !default;
$card-border-color:        rgba($black,.125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:              rgba($black, .03) !default;
$card-bg:                  $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin:          math.div($grid-gutter-width, 2) !default;

$card-columns-count:        3 !default;
$card-columns-gap:          1.25rem !default;
$card-columns-margin:       $card-spacer-y !default;


// Tooltips

$tooltip-max-width:           200px !default;
$tooltip-color:               $white !default;
$tooltip-bg:                  $black !default;
$tooltip-opacity:             .9 !default;
$tooltip-padding-y:           3px !default;
$tooltip-padding-x:           8px !default;
$tooltip-margin:              0 !default;


$tooltip-arrow-width:         5px !default;
$tooltip-arrow-height:        5px !default;
$tooltip-arrow-color:         $tooltip-bg !default;


// Popovers

$popover-inner-padding:               1px !default;
$popover-bg:                          $white !default;
$popover-max-width:                   276px !default;
$popover-border-width:                $border-width !default;
$popover-border-color:                rgba($black,.2) !default;
$popover-box-shadow:                  0 5px 10px rgba($black,.2) !default;

$popover-header-bg:                    darken($popover-bg, 3%) !default;
$popover-header-color:                 $headings-color !default;
$popover-header-padding-y:             8px !default;
$popover-header-padding-x:             14px !default;

$popover-body-color:               $body-color !default;
$popover-body-padding-y:           9px !default;
$popover-body-padding-x:           14px !default;

$popover-arrow-width:                 10px !default;
$popover-arrow-height:                5px !default;
$popover-arrow-color:                 $popover-bg !default;

$popover-arrow-outer-width:           ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color:           fade-in($popover-border-color, .05) !default;


// Badges

$badge-color:                 $white !default;
$badge-font-size:             75% !default;
$badge-font-weight:           $font-weight-bold !default;
$badge-padding-y:             .25em !default;
$badge-padding-x:             .4em !default;

$badge-pill-padding-x:        .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:    10rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding:         15px !default;

$modal-dialog-margin:         10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;

$modal-title-line-height:     $line-height-base !default;

$modal-content-bg:               $white !default;
$modal-content-border-color:     rgba($black,.2) !default;
$modal-content-border-width:     $border-width !default;
$modal-content-box-shadow-xs:    0 3px 9px rgba($black,.5) !default;
$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black,.5) !default;

$modal-backdrop-bg:           $black !default;
$modal-backdrop-opacity:      .5 !default;
$modal-header-border-color:   $gray-200 !default;
$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width:   $modal-content-border-width !default;
$modal-footer-border-width:   $modal-header-border-width !default;
$modal-header-padding:        15px !default;

$modal-lg:                    800px !default;
$modal-md:                    500px !default;
$modal-sm:                    300px !default;

$modal-transition:            transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:             .75rem !default;
$alert-padding-x:             1.25rem !default;
$alert-margin-bottom:         1rem !default;
$alert-border-radius:         $border-radius !default;
$alert-link-font-weight:      $font-weight-bold !default;
$alert-border-width:          $border-width !default;


// Progress bars

$progress-height:               1rem !default;
$progress-font-size:            .75rem !default;
$progress-bg:                   $gray-200 !default;
$progress-border-radius:        $border-radius !default;
$progress-box-shadow:           inset 0 .1rem .1rem rgba($black,.1) !default;
$progress-bar-color:            $white !default;
$progress-bar-bg:               theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition:       width .6s ease !default;

// List group

$list-group-bg:                  $white !default;
$list-group-border-color:        rgba($black,.125) !default;
$list-group-border-width:        $border-width !default;
$list-group-border-radius:       $border-radius !default;

$list-group-item-padding-y:      .75rem !default;
$list-group-item-padding-x:      1.25rem !default;

$list-group-hover-bg:                 $gray-100 !default;
$list-group-active-color:             $component-active-color !default;
$list-group-active-bg:                $component-active-bg !default;
$list-group-active-border-color:      $list-group-active-bg !default;

$list-group-disabled-color:      $gray-600 !default;
$list-group-disabled-bg:         $list-group-bg !default;

$list-group-action-color:             $gray-700 !default;
$list-group-action-hover-color:       $list-group-action-color !default;

$list-group-action-active-color:      $body-color !default;
$list-group-action-active-bg:         $gray-200 !default;


// Image thumbnails

$thumbnail-padding:           .25rem !default;
$thumbnail-bg:                $body-bg !default;
$thumbnail-border-width:      $border-width !default;
$thumbnail-border-color:      #ddd !default;
$thumbnail-border-radius:     $border-radius !default;
$thumbnail-box-shadow:        0 1px 2px rgba($black,.075) !default;
$thumbnail-transition:        all .2s ease-in-out !default;


// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color:     $gray-600 !default;


// Breadcrumbs

$breadcrumb-padding-y:          $spacer*.5 !default;
$breadcrumb-padding-x:          0 !default;
$breadcrumb-item-padding:       $spacer*.5 !default;
$breadcrumb-min-height:         40px !default;
$breadcrumb-margin-bottom:      1rem !default;
$breadcrumb-total-height:       calc(#{$breadcrumb-min-height} + #{$breadcrumb-margin-bottom}) !default;
$breadcrumb-bg:                 $body-bg !default;
$breadcrumb-divider-color:      $gray-750 !default;
$breadcrumb-item-color:         $gray-750 !default;
$breadcrumb-active-weight:      600 !default;
$breadcrumb-divider:            ">" !default;


// Carousel

$carousel-control-color:                      $white !default;
$carousel-control-width:                      15% !default;
$carousel-control-opacity:                    .5 !default;

$carousel-indicator-width:                    30px !default;
$carousel-indicator-height:                   3px !default;
$carousel-indicator-spacer:                   3px !default;
$carousel-indicator-active-bg:                $white !default;

$carousel-caption-width:                      70% !default;
$carousel-caption-color:                      $white !default;

$carousel-control-icon-width:                 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition:           transform .6s ease !default;


// Close

$close-font-size:             $font-size-base * 1.5 !default;
$close-font-weight:           $font-weight-bold !default;
$close-color:                 $black !default;
$close-text-shadow:           0 1px 0 $white !default;

// Code

$code-font-size:              90% !default;
$code-padding-y:              .2rem !default;
$code-padding-x:              .4rem !default;
$code-color:                  #bd4147 !default;
$code-bg:                     $gray-100 !default;

$kbd-color:                   $white !default;
$kbd-bg:                      $gray-900 !default;

$pre-color:                   $gray-900 !default;
$pre-scrollable-max-height:   340px !default;


// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;


// Layout
$header_height: 4rem;
$mobile_header_height: 48px;
$header_line_height: 1.5rem;
$toggle_height: 48px;
$left-drawer-width: 16vw;
$left-drawer-item-width: 14vw;
$right-drawer-width: 320px;
$center-section-spacing: $spacer;
$center-section-width: 100% !default;


// Transitions
$transition_bezier: .05, .74, .27, .99;
$transition_ease: cubic-bezier(0.4, 0.0, 0.2, 1);
$transition_ease_out: cubic-bezier(0.0, 0.0, 0.2, 1);
$transition_ease_in: cubic-bezier(0.4, 0.0, 1, 1);
$transition_ease_out_quickview: cubic-bezier(.05, .74, .2, .99);
$transition: 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

// Zindex
$zindex_page_container: 100;
$zindex_sidebar: 1200;
$zindex_checkinbar: ($zindex_sidebar + 50);
$zindex_header: ($zindex_sidebar + 100);
$zindex_popper: 2000;

// Light background sidebar
$light_nav_text_color:                $body-color;
$light_nav_link_hover_bg:             transparent;
$light_nav_link_hover_color:          theme-color("primary");
$light_nav_link_active_bg:            transparent;
$light_nav_link_active_color:         theme-color("primary");
$light_nav_link_open_bg:              rgba(0,0,0,.05);
$light_nav_link_open_color:           $light_nav_link_active_color;
$light_nav_sub_nav_bg:                $light_nav_link_open_bg;
$light_nav_sub_link_active_color:     theme-color("primary");
$light_nav_sub2_nav_bg:               rgba(0,0,0,.05);
$light_nav_sub3_nav_bg:               rgba(0,0,0,.05);

// Themes
$theme_dark_text_color:       rgba(255,255,255,.7);
$theme_dark_text_color_light: rgba(255,255,255,.25);
$theme_dark_sidebar_bg_color: $dark;
$theme_dark_header_bg_color:  lighten($dark, 6%);
$theme_dark_page_bg:          lighten($dark, 2.5%);
$theme_dark_box_bg:           $theme_dark_header_bg_color;
$theme_gray_sidebar_bg_color: #3c3c3c;
$theme_gray_header_bg_color:  #4c4c4c;
$theme_gray_page_bg:          #444;
$theme_gray_text_color:       $theme_dark_text_color;
$theme_gray_box_bg:           $theme_gray_header_bg_color;

// Accent borders
$accent-border-width: 6px !default;

// Scrollbars
$scrollbar-bg: #CFD8DC !default;
$scrollbar-thumb-bg: #90A4AE !default;

$state-default-bg: rgba(0,0,0, .1) !default; // also: pagination bg
$state-default-border: darken(adjust-hue($state-default-bg, -10), 5%) !default; 

$switch-track-bg: #52d869 !default;

$checkout-spacing: 1rem;
$checkout-button-size: 7rem !default;
$cart-header-padding: 0.5rem !default;
$cart-bg: $gray-200;
