@import "~styles/variables";

.cart-toggle {
  width: $header_height;
  height: $header_height;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: $gradient_bg;
  }

  .toggle-icon-button {
    font-size: ($header_height - $spacer * 2);
    padding: $spacer;

    .item-count-badge {
      min-width: 18px;
      min-height: 18px;

      &.zero-item-count {
        background-color: $header_bg;
        border: 2px solid #fff;
      }
    }
  }

  &.disabled {
    .toggle-icon-button {
      .item-count-badge {
        color: inherit;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        background-color: $header_bg;
      }
    }
  }
}