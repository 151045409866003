.staff-search-list-item {
  padding-left: 12px !important;
  padding-right: 0 !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;

  .staff-name {
    margin-left: 8px;
  }
}
