.pseudo-link { 
  cursor: pointer !important;
  color: $link-color !important;
  text-decoration: $link-decoration !important;
  text-transform: none !important;
  background-color: transparent !important;
  padding: 0 !important;

  &.always-underlined {
    text-decoration: $link-hover-decoration !important;
  }

  &:hover { 
    color: $link-hover-color !important;
    text-decoration: $link-hover-decoration !important;
  }

  &.Mui-disabled {
    color: $theme-action-disabled !important;
  }

  &.danger {
    color: $danger !important
  }
}