@import "~styles/variables";

#qr-scanner-switch {
  .switch-root {
    width: 42px !important;
    height: 26px !important;
    padding: 0 !important;
    margin: $spacer*0.5 $spacer*0.5 0 $spacer*0.5 !important;

    .thumb {
      width: 24px;
      height: 24px;
    }

    .track {
      border-radius: 13px;
      border: 1px solid #bdbdbd;
      background-color: $bright;
      opacity: 1;
      transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .switch-base {
      padding: 1px;

      &.is-checked {
        transform: translateX(16px);
        color: #fff;

        & + .track {
          background-color: $switch-track-bg;
          opacity: 1;
          border: none;
        }
      }

      &.focus-visible .thumb {
        color: $switch-track-bg;
        border: 6px solid #fff;
      }
    }
  }

  .switch-label {
    font-size: 0.75rem;
    white-space: nowrap;

    @media (max-width: grid-breakpoint("md")) {
      white-space: normal;
      line-height: 1.25;
    }
  }
}
