@import "~styles/variables";

.fixed-content-wrapper {
  min-height: 100%;
  position: relative;
  background-color: $body-bg;

  .fixed-content {
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    transition: padding $transition;
    transition: all 0.3s ease;
    z-index: 10;
    padding: $center-section-spacing;

    .fixed-header & {
      margin-top: $header_height;
      height: calc(100vh - #{$header_height});
    }

    .right-drawer-open & {
      margin-right: $right-drawer-width;
      width: calc(100vw - #{$right-drawer-width});
    }

    .left-drawer-open & {
      margin-left: $left-drawer-width;
      width: calc(100vw - #{$left-drawer-width});
    }

    .right-drawer-open.left-drawer-open & {
      width: calc(100vw - #{$right-drawer-width} - #{$left-drawer-width});
    }
  }
}
