@import "~styles/variables";

.status-overlay {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex_popper + 1;

  &.fixed {
    position: fixed;
  }

  &.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    .spinner {
      color: #fff;
    }
  }
}
