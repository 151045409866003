@import "variables";

.unlogged-screen {
  background-image: url('~assets/haystack.jpg');
  background-size: cover;
  background-position: right;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-background {
    background-color: $primary;

    .form-card {
      overflow: auto;
      max-height: 500px;
      margin: -$spacer $spacer $spacer $spacer;
      width: 480px;
      padding: $spacer;

      .back-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .form-actions {
        padding-top: $spacer;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .eula-link {
        margin-top: $spacer;
        padding: 0px;
        background-color: transparent;
        font-weight: 500;
        font-size: inherit;
        line-height: inherit;
        vertical-align: baseline;
        text-transform: none;
      }
    }

    .reset-password-container {
      text-align: right;
      padding: $spacer;

      .reset-password-link {
        padding: 5px;
        color: $bright;
        background-color: transparent;
        font-weight: 500;
      }
    }
  }
}
