@import "~styles/variables";

.sidenav-content-wrapper {
  flex: 1;
  position: relative;
  
  .sidenav-content {
    padding-top: $spacer*.5;
    height: 100%;

    .nav-divider {
      background-color: rgba(255, 255, 255, .4);
      margin: 3px auto;
      width: $left-drawer-item-width;
    }

    .nav-item {
      display: flex;
      border-radius: 5px;
      -moz-border-radius: 5px;
      color: white;
      padding-left: 8px;
      padding-right: 8px;
      margin: auto;
      width: $left-drawer-item-width;

      .nav-icon {
        color: white;
        margin-right: 0;
        min-width: 44px;

        @media only screen and (max-width: $screen_md_max) {
          min-width: 24px;
        }

        @media only screen and (max-width: $screen_sm_max) {
          display: none;
        }
      }

      .nav-text-wrapper {
        @media only screen and (max-width: $screen_md_max) {
          padding-left: 8px;
        }

        @media only screen and (max-width: $screen_sm_max) {
          padding-left: 0px;
        }
      }

      .nav-text-wrapper-inset {
        @media only screen and (max-width: $screen_lg_max) {
          padding-left: 40px;
        }

        @media only screen and (max-width: $screen_md_max) {
          padding-left: 20px;
        }

        @media only screen and (max-width: $screen_sm_max) {
          padding-left: 0px;
        }
      }

      .nav-text {
        font-weight: 400;
        color: rgba(255, 255, 255, .7);
      }

      &.active-nav-item {
        background-color: rgba(255, 255, 255, .2);

        .nav-text {
          font-weight: 400;
          color: #fff;
        }
      }
    }
  }
}
