@import "~styles/variables";

.change-location-dialog {
  .dialog-title {
    background-color: $primary;
    text-align: center;
    padding: 15px;

    > * {
      color: #fff;
      font-weight: 400;
    }
  }

  .dialog-content {
    max-width: 80vw;
    max-height: 80vh;
    background-color: $page-header-bg;
    padding: 0;

    .stepper {
      background-color: $page-header-bg;
    }

    .data-list {
      box-shadow: none;
    }
  }
}