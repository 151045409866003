@import "~styles/variables";

.cart-selector {
  display: inline-block;

  .arrow-drop-down {
    margin-right: 4px;
  }
}

.cart-selector-menu {
  max-height: 380px;
  width: 250px !important;
  z-index: 100;

  .cart-selector-avatar {
    width: 2rem;
    height: 2rem;
    margin-top: 2px;
    margin-bottom: 2px;
    letter-spacing: -2px;
    font-size: 18px;
  }

  .cart-selector-menu-item {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 12px;

    .cart-selector-listitem-avatar {
      min-width: 46px;
    }

    .old-cart {
      color: rgba($danger, 0.75);
    }
  //   &.old {
  //     &:after {
  //       content: " ";
  //       position: absolute;
  //       left: 0;
  //       top: 0;
  //       bottom: 0;
  //       border-left: $accent-border-width solid $danger;
  //     }
  //   }
  }

  .cart-selector-listitem-secondary {
    right: 10px;
  }
}
