.checkboxes { 
  margin-top: 8px !important;
  background-color: inherit;
  
  .input-label {
    padding-left: 8px;
    padding-right: 8px;
    left: -4px;
    background-color: inherit;
  }

  .picky .picky__input {
    min-width: 9rem;
  }
}