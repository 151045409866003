.file-uploader {
  padding: 16px;

  .dropzone-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: initial;
    min-height: 200px;
    border: 2px grey dashed;
    
    > * {
      margin-bottom: 8px;
    }
  }
}
