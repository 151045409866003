.PhoneInput {
  display: flex !important;
  
  .PhoneInputInput {
    border: none;

    &:focus {
      outline: 0;
    }
  }
}
