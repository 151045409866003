@import "~styles/variables";

.lock-screen {
  .lock-screen-paper {
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;

    .lock-screen-card {
      padding: $spacer;

      .lock-screen-card-content {
        padding-top: 0;
        display: flex;
        align-items: center;

        .password-field {
          min-width: 20rem;
        }

        .unlock-button {
          margin-left: $spacer;

          .unlock-button-icon {
            margin-right: 4px;
          }
        }
      }

      .lock-screen-card-actions {
        justify-content: center;
        
        .logout-link {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}