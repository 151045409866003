@import "~styles/variables";

.search-list-container {
  width: 100%;
  background-color: inherit;

  &.outlined.has-items {
    .generic-search {
      margin-bottom: 0;

      .react-select-container {
        .react-select__control {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    .search-list {
      padding-top: 8px;
      padding-bottom: 8px;

      border: 1px solid $border-color;
      border-top: 0;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    &.has-error {
      .search-list {
        border: 1px solid #f44336;
        border-radius: $border-radius;
      }
    }
  }
}