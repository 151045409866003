$spinner-width:         2rem !default;
$spinner-height:        $spinner-width !default;
$spinner-border-width:  .25em !default;

$spinner-width-sm:        1rem !default;
$spinner-height-sm:       $spinner-width-sm !default;
$spinner-border-width-sm: .2em !default;

$spinner-width-lg:        3rem !default;
$spinner-height-lg:       $spinner-width-lg !default;
$spinner-border-width-lg: .15em !default;

$spinner-width-xl:        5rem !default;
$spinner-height-xl:       $spinner-width-xl !default;
$spinner-border-width-xl: .45em !default;


@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border {
  display: inline-block;
  width: $spinner-width;
  height: $spinner-height;
  vertical-align: text-bottom;
  border: $spinner-border-width solid currentColor;
  border-right-color: transparent;
  // stylelint-disable-next-line property-blacklist
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

.spinner-border-lg {
  width: $spinner-width-lg;
  height: $spinner-height-lg;
  border-width: $spinner-border-width-lg;
}

.spinner-border-xl {
  width: $spinner-width-xl;
  height: $spinner-height-xl;
  border-width: $spinner-border-width-xl;
}
