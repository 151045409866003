.styled-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-thumb-bg $scrollbar-bg;

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-bg;
    border-radius: 6px;
    border: 3px solid $scrollbar-bg;
  }
}