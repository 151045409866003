@import "~styles/variables";

$icon-size: $spacer*3;

.side-cart {
  position: relative;
  background-color: $cart-bg;
  flex: 1;

  .side-cart-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .cart {
      flex: 1;
      min-width: $right-drawer-width;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .cart-header {
        padding: $spacer*.5;
      }

      .cart-items-scrollbar {
        .cart-items {
          .cart-item {
            .cart-item-col {
              margin-left: $spacer;
            }

            .image {
              margin-left: 0px;
              min-height: $icon-size;
              height: $icon-size;
              min-width: $icon-size;
              width: $icon-size;

              .product-avatar {
                height: $icon-size !important;
                width: $icon-size !important;
              }
              
              .mp-icon-wrapper {
                font-size: $icon-size;
                line-height: 2rem;
              }
            }

            .details {
              .item-name {
                font-size: 0.875rem;
              }

              .item-additional-details {
                font-size: 0.75rem;
              }
            }

            .breaker {
              display: block;
            }

            .pricing {
              order: 5;
              min-width: initial;
              font-size: 0.8125rem;
            }

            .item-ticker {
              order: 4;
              margin-left: 0px;
            }

            .total {
              min-width: initial;
              font-size: 1rem;
            }
          }
        }
      }
    }

    .bottom-wrapper {
      display: flex;
      flex-direction: column;
      padding: $spacer*0.75;

      .bottom-right-wrapper {
        display: flex;
        flex-direction: column;

        .cart-totals-wrapper {
          margin-top: $spacer;
        }
      }
    }

    .bottom-actions {
      display: flex;
      padding: 0;

      .checkout-button {
        background-color: $success;
        color: #fff;
        margin: 0;
        border-radius: 0;
        box-shadow: none;

        &.disabled {
          background-color: $theme-action-disabled-background;
        }
      }
    }
  }
}