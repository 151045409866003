@import "~styles/variables";

$large-icon-size: $spacer*6;

.cart {
  // padding-bottom: 2px;

  .cart-header {
    padding: $cart-header-padding;
    border-bottom: 2px solid $cart-bg;

    &.cart-order-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .cart-header-action {
      margin-top: 0;
    }
  }

  .cart-items-scrollbar {
    display: flex;
    flex: 1;
    overflow-y: auto;

    .cart-items {
      height: 100%;
      width: 100%;

      .cart-item {
        position: relative;
        display: flex;
        padding: $spacer*.5;
        border-radius: 0;
        justify-content: space-between;
        flex-wrap: wrap;

        &:not(:first-child) {
          border-top: 2px solid $cart-bg;
        }
        
        .cart-item-col {
          margin-left: $spacer*2;
        }

        .image {
          order: 1;
          margin-left: 0px;
          min-height: $large-icon-size;
          height: $large-icon-size;
          min-width: $large-icon-size;
          width: $large-icon-size;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          .product-avatar {
            height: $large-icon-size !important;
            width: $large-icon-size !important;
          }
          
          > img, > svg {
            height: 100%;
            width: 100%;
          }

          .mp-icon-wrapper {
            font-size: $large-icon-size;
            line-height: 1rem;
          }
        }

        .details {
          order: 2;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .item-name {
            display: block;
            font-size: 1.5rem;
            font-weight: 500;
          }

          .item-additional-details {
            display: block;
            font-size: 1rem;
            color: $gray-800;
            font-style: italic;
          }
        }

        .breaker {
          order: 3;
          height: $spacer*.5;
          width: 100%;
          content: '';
          display: none;

          @media only screen and (max-width: $screen_sm_max) {
            display: block;
          }
        }

        .pricing {
          order: 4;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-weight: 500;
          font-size: 1rem;
          min-width: 4rem;
        }

        .item-ticker {
          order: 5;
          min-width: 6.125rem;
          display: flex;
          justify-content: center;
          
          .item-ticker-inner {
            display: flex;
            align-items: center;
            text-align: center;
          
            .item-quantity {
              font-size: 1.875rem;
              font-weight: 500;
              width: 3rem;
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              > div {
                font-size: inherit;

                &:before {
                  border-bottom: none;
                }
              }

              input {
                text-align: center;
                padding: 0;
              }

              &:before {
                display: none;
              }
            }
          }
        }

        .total {
          order: 6;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 4.5rem;
          font-size: 1.25rem;

          > p {
            font-size: inherit;
          }
        }

        &:hover .remove-fab {
          opacity: 1;
        }
      }

      .discount {
        color: $danger;

        &.discount-small {
          font-size: 0.8rem;
        }
      }
    }
  }
}
