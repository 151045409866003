#draggable-dialog-items-container {
  .drag-handle {
    cursor: move;
  }

  .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
  }

  .gu-hide {
    display: none !important;
  }

  .gu-unselectable {
    user-select: none !important;
  }

  .gu-transit {
    opacity: 0.2;
  }
}
