@import "~styles/variables";

.product-search {
  min-width: 16rem !important;

  .product-search-item {
    margin-top: 4px;
    margin-bottom: 4px;

    .product-name {
       margin-left: 8px;
    }
  }
}