@import "~styles/variables";

.resume-membership-dialog {
  .resume-membership-dialog-content {
    display: flex;
    flex-direction: column;

    > * + * {
      margin-top: $spacer;
    }


    .resume-membership-radio-options {
      gap: $spacer;

      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
  
        > * + * {
          margin-left: $spacer*1;
        }
      }
    }
  }
}