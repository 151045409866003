@import "~styles/variables";

.back-button-wrapper {
  .back-button {
    line-height: $line-height-base;
  }

  &.absolute {
    position: absolute !important;
    top: 16px !important;
    left: 16px !important;
  }

  &.dense {
    .back-button {
      padding: 3px 6px;
    }
  }
}
